import { Box, Chip } from '@mui/material'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { ROUTE_CONFIGURATION, ROUTE_USER_PROFILE } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import styles from './navbarResponsive.module.css'
import style from './mobilePreProfile.module.css'
import React from 'react'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import logoEsfera from '../../assets/esfera/logo-esfera.svg'
import icoProfile from '../../assets/esfera/nav-profile-icons/ico-perfil.svg'
import { useGetProfilePhoto } from '../../hooks/profilePhoto/useGetProfilePhoto'
import dayjs from 'dayjs'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

type preProfileProps = {
  handleClosePreProfile: () => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function MobilPreProfile(props: preProfileProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const activeCircle = UserCircleActiveService.getActiveFullUserCircle()
  const logoutURL = process.env.REACT_APP_EXIT_URL
  const { profilePhotoData } = useGetProfilePhoto()

  const handleRouteProfile = () => {
    props.handleClosePreProfile()
    navigate(ROUTE_USER_PROFILE)
  }

  const handleRouteConfiguration = () => {
    props.handleClosePreProfile()
    navigate(ROUTE_CONFIGURATION)
  }

  const handleRouteSalir = () => {
    props.handleClosePreProfile()
    handlerLogout()
  }

  const handlerLogout = () => {
    if (logoutURL) {
      window.location.replace(logoutURL)
      loggedUserService.logout()
    }
    loggedUserService.logout()
    localStorage.clear()
  }

  function getAge(birthDate: Date) {
    const today = dayjs()
    const birth = dayjs(birthDate)
    let age = today.year() - birth.year()

    // Ajusta la edad si el cumpleaños aún no ha ocurrido este año
    if (
      today.month() < birth.month() ||
      (today.month() === birth.month() && today.date() < birth.date())
    ) {
      age--
    }

    return age
  }

  return (
      <Box className={style.mainContainer}>
        <Box className={style.logoBox}>
          <img src={logoEsfera} alt={t('logo')} width={120} height={60} style={{ width: 120, height: 60}}/>
        </Box>
        <Box className={style.container}>
          <h1 className={style.title}>{t('myProfile')}</h1>
          {activeCircle?.circle.name && (
            <Box className={style.circleBox}>
              <Chip
                style={{
                  background: '#0163A6',
                  color: '#FFF',
                  width: '50%',
                  fontWeight: 'bold',
                }}
                label={activeCircle?.circle.name}
              />
              <p className={style.circleUser}>{activeCircle?.user.name}</p>
            </Box>
          )}
          <Box className={style.profileContainer}>
            {profilePhotoData !== '' ? (
              <>
                <img
                  style={{
                    height: '25%',
                    width: '25%',
                    background: '#FFF',
                    borderRadius: 100,
                  }}
                  src={`data:image/jpeg;base64,${profilePhotoData}`}
                  alt={'selectedProfilePhoto'}
                />
              </>
            ) : (
              <img
                style={{
                  height: '25%',
                  width: '25%',
                  background: '#68B3E0',
                  borderRadius: 100,
                }}
                src={icoProfile}
                alt={icoProfile}
              />
            )}
            <Box className={style.profileInfo}>
              <h3 className={style.subtitle}>{loggedUser?.name}</h3>
              <h4 className={style.text}>
                {dayjs(loggedUser?.birthDate).format('DD/MM/YYYY') +
                  ' (' +
                  getAge(loggedUser?.birthDate ?? new Date())}{' '}
                {t('years')}{')'}
              </h4>
              <h4 className={style.text}>DNI: {loggedUser?.dni || "-"}</h4>
              <h4 className={style.text}>CIP: {loggedUser?.cip || "-"}</h4>
            </Box>
          </Box>
        </Box>

        <Box className={styles.bordedMainContainer}>
          <Box className={styles.preProfileContainer}>
            <Box className={styles.buttonContainer}>
              <AppButton
                label={t('profile')}
                theme={ButtonTheme.NewPrimary}
                handler={() => handleRouteProfile()}
                type={'button'}
                fullWidth={true}
              />
            </Box>
            <Box className={styles.buttonContainer}>
              <AppButton
                label={t('config')}
                theme={ButtonTheme.NewPrimary}
                handler={() => handleRouteConfiguration()}
                type={'button'}
                fullWidth={true}
              />
            </Box>
            <Box className={styles.buttonContainer}>
              <AppButton
                label={t('exit')}
                theme={ButtonTheme.NewPrimary}
                handler={() => handleRouteSalir()}
                type={'button'}
                fullWidth={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
  )
}
