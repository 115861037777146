import { Box, Modal } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AppTable, Field } from '../../../components/table'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Actions, Pager } from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import { Landmark } from '../../../modules/patient-data/models/Landmark'
import { CustomModal } from '../../../components/modal/CustomModal'
import { Query, QueryParam } from '../../../common/api/Query'
import styles from '../../generic/GenericTable.module.css'
import stylesData from './FamiliarData.module.css'
import changeStateIcon from '../../../assets/table_icons/ico-edit.svg'
import { LANDMARK_SERVICE_KEY } from '../../../modules/patient-data'
import { LandMarkService } from '../../../modules/patient-data/services/LandmarkService'
import { fromModel } from '../../../modules/patient-data/models/LandmarkDTO'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import styleMedicalData from './FamiliarData.module.css'
import flag from '../../../assets/active_plan_icons/flag.svg'
import flagHover from '../../../assets/active_plan_icons/flag_hover.svg'
import closeIcon from '../../../assets/buttons/close.svg'

const landmarkContainer = getPatientDataContainer()
const landmarkService = landmarkContainer.get<LandMarkService>(LANDMARK_SERVICE_KEY)

export const LandmarkTable = () => {
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [landmarks, setLandmarks] = useState<ItemList<Landmark>>(emptyList<Landmark>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pager, setPager] = useState<Pager>()
  const [landmarksPerPage, setLandmarksPerPage] = useState<number>(10)
  const [currentLandmark, setCurrentLandmark] = useState<Landmark>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentTableLandmark, setCurrentTableLandmark] = useState<Landmark[]>([])
  const [openTableModal, setOpenTableModal] = useState<boolean>(false)
  const [currentTitle, setCurrentTitle] = useState<number>()
  const [isHover, setIsHover] = useState<boolean[]>([])
  const isRelated = useIsRelated();

  useEffect(() => {
    if (!isLoading || !activeUser?.id) {
      return
    }
    landmarkService
      .getFilteredList(
        new Query({
          query: [new QueryParam('userID', activeUser.user.id)],
          sort: [{ field: 'date', desc: false }],
        })
      )
      .subscribe((res) => {
        const newList = emptyList<Landmark>()
        newList.items = res.items
        newList.count = res.count
        setLandmarks(newList)
        setCount(newList.count)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: landmarksPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, landmarksPerPage])

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(e.target.value)) {
      setLandmarksPerPage(10)
      return
    }
    setLandmarksPerPage(Number.parseInt(e.target.value))
  }

  const changeState = (e: Landmark) => {
    if (e.state) return
    if (e) setCurrentLandmark(e)
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleSave = () => {
    if (currentLandmark) {
      const newLandmark = Object.assign(
        { ...fromModel(currentLandmark) },
        { state: true, endDate: new Date() }
      )
      landmarkService.update(newLandmark).subscribe((_) => setIsLoading(!isLoading))
    }
    const auxTableArr = [...currentTableLandmark]
    const auxIndex = auxTableArr.findIndex((element) => element.id === currentLandmark?.id)
    auxTableArr[auxIndex].state = true
    auxTableArr[auxIndex].endDate = new Date()
    setCurrentTableLandmark(auxTableArr)
    setCurrentLandmark(undefined)
    setOpenModal(false)
  }

  const fields: Field<Landmark>[] =
    innerWidth > 599
      ? [
          {
            name: 'title',
            label: t('landmark'),
            styleFunc: (f, i) =>
              i.state ? styleMedicalData.completedStatus : styleMedicalData.pendingStatus,
          },
          {
            name: 'endDate',
            label: t('completedDate'),
            styleFunc: (f, i) =>
              i.state ? styleMedicalData.completedStatus : styleMedicalData.pendingStatus,
            renderFunc: (f, i) => {
              const dateEnd = new Date(i.endDate)
              if (dateEnd.getFullYear() === 0) {
                return ''
              } else {
                return dateEnd.toLocaleDateString()
              }
            },
          },
          {
            name: 'state',
            label: t('state'),
            styleFunc: (f, i) =>
              t(
                i.state
                  ? styleMedicalData.completedStatusCustom
                  : styleMedicalData.pendingStatusCustom
              ),
            renderFunc: (f, i) => t(i.state ? 'completed' : 'pending'),
          },
        ]
      : [
          {
            name: 'id',
            label: '',
            styleFunc: (f, i) =>
              i.state ? styleMedicalData.completedStatus : styleMedicalData.pendingStatus,
            renderFunc: (f, i) => {
              return (
                <Box className={stylesData.containerRowTimeBox}>
                  <Box
                    className={stylesData.containerSubMainTableResponsive}
                    onClick={() => changeState(i)}
                  >
                    <Box className={stylesData.titleTableGrowMonitoring}>{t('landmark')}</Box>
                    <Box className={stylesData.informationTableResponsive}>{i.title}</Box>
                    <Box className={stylesData.containerRowTimeBox}>
                      <Box>
                        {new Date(i.endDate).getFullYear() !== 0
                          ? new Date(i.endDate).toLocaleDateString()
                          : ''}
                      </Box>
                      <Box>
                        {new Date(i.endDate).getFullYear() !== 0
                          ? new Date(i.endDate).toLocaleTimeString().substr(0, 5)
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            },
          },
        ]

  const ic = (l: Landmark): boolean => l.state
  const actions: Actions<Landmark> = {
    actionsColumn: innerWidth > 599 ? t('Actions') : '',
    styleFunc: (i) => (i.state ? styleMedicalData.completedStatus : styleMedicalData.pendingStatus),
    items:
      innerWidth > 599
        ? [
            {
              handler: changeState,
              icon: changeStateIcon,
              label: 'changeState',
              hidden: isRelated ? (l) => ic(l) : (l) => true,
            },
            // {
            //   handler: changeState,
            //   icon: changeStateIcon,
            //   label: 'changeState',
            //   hidden: useIsRelated() ? (l) => !ic(l) : (l) => true,
            // },
          ]
        : [],
  }

  // filtro por la fecha
  const item: any[] = useMemo(() => {
    let m = 0
    let y = 0
    let n = 0
    const arr: any[] = []
    let xarr: any[] = []
    let sarr: any[] = []
    landmarks.items.forEach((element) => {
      n++
      if (
        element.date.getMonth() === landmarks.items[0].date.getMonth() + m &&
        element.date.getFullYear() === landmarks.items[0].date.getFullYear() + y
      ) {
        xarr.push(element)
        if (landmarks.items.length === n) {
          sarr.push(m + 12 * y)
          sarr.push(xarr)
          arr.push(sarr)
        }
      } else {
        sarr.push(m + 12 * y)
        sarr.push(xarr)
        arr.push(sarr)
        xarr = []
        sarr = []
        m = element.date.getMonth() - landmarks.items[0].date.getMonth()
        y = element.date.getFullYear() - landmarks.items[0].date.getFullYear()
        xarr.push(element)
      }
    })
    return arr
  }, [landmarks])

  const handleOpenModal = (currentTitle: number, currentTable: Landmark[]) => {
    setOpenTableModal(true)
    setCurrentTableLandmark(currentTable)
    setCurrentTitle(currentTitle)
  }

  const handleCloseTabaleModal = () => {
    setOpenTableModal(false)
    setCurrentTableLandmark([])
  }

  const handleHoverCardEnter = (value: number) => {
    const auxValue = [...isHover]
    auxValue.forEach((element, index) => {
      if (value === index) {
        auxValue[index] = true
      } else {
        auxValue[index] = false
      }
    })
    setIsHover(auxValue)
  }

  const handleHoverCardLeave = (value: number) => {
    const auxValue = [...isHover]
    auxValue.forEach((element, index) => {
      auxValue[index] = false
    })
    setIsHover(auxValue)
  }

  return (
    <Box>
      <Box className={styleMedicalData.mainLandmarkContainer}>
        {item.map((element, index) => {
          isHover.push(false)
          return (
            <Box
              className={styleMedicalData.containerCardBox}
              onClick={() => handleOpenModal(element[0], element[1])}
              onMouseOver={() => handleHoverCardEnter(index)}
              onMouseOut={() => handleHoverCardLeave(index)}
              key={element[0]}
            >
              <Box className={styleMedicalData.imageBox}>
                <img src={isHover[index] ? flagHover : flag} />
              </Box>
              <Box className={styleMedicalData.cardText}>{element[0] + ' meses'}</Box>
            </Box>
          )
        })}
      </Box>

      <Modal open={openTableModal} onClose={handleCloseTabaleModal}>
        <Box className={styleMedicalData.modalScrollContainer}>
          <Box className={styleMedicalData.modalContainerTable}>
            <Box className={styleMedicalData.containerRow}>
              <Box className={styleMedicalData.titleModalTable}>
                {t('landmarks') + ' de ' + currentTitle + ' meses'}
              </Box>
              <Box onClick={handleCloseTabaleModal}>
                <img src={closeIcon} />
              </Box>
            </Box>

            {currentTableLandmark.length !== 0 ? (
              <AppTable
                items={currentTableLandmark}
                rowKeyField="id"
                fields={fields}
                actions={actions}
              />
            ) : (
              <h1 key="no-landmarks" className={styles.noElements}>
                {t('noLandmarks')}
              </h1>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal open={openModal} onClose={handleCloseModal}>
        <CustomModal
          handleClose={handleCloseModal}
          handleSave={handleSave}
          title={t('changeMilestone')}
          warningText={t('irreversibleLandmarkAction')}
        />
      </Modal>
    </Box>
  )
}
