import { getUserContainer } from '../../container/user-module'
import { IUserService } from '../../modules/users'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { useEffect, useState } from 'react'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { useGetActiveUser } from '../get-active-user/useGetActiveUser'
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export const useIsRelated = () => {
  const loggedUser = loggedUserService.get()
  const circle = UserCircleActiveService.getActiveUser()
  const [isRelated, setIsRelated] = useState<boolean>(false)

  useEffect(() => {
    if (!loggedUser || !circle) {
      return
    }
    if (!loggedUser.roles.includes('professionalSMS')) {
      setIsRelated(true)
    } else {
      userService.getUsersByDoctor(loggedUser.id).subscribe((res) => {
        res.forEach((element) => {
          if (element.id === circle.id) {
            setIsRelated(true)
          }
        })
        if (res.length === 0) {
          setIsRelated(true)
        }
      })
    }
  }, [loggedUser, circle])

  return isRelated
}

export const useCanSeeQuery = (): boolean => {
  const loggedUser = loggedUserService.get()
  const { activeUser } = useGetActiveUser()
  if (!loggedUser) return false
  if (
    (typeof activeUser.profSmsID === 'string' && activeUser.profSmsID === loggedUser.id) ||
    (typeof activeUser.familiarsID === 'string' && activeUser.familiarsID === loggedUser.id) ||
    activeUser.user.id === loggedUser.id
  ) {
    return true
  }
  if (loggedUser.roles.some((role) => role.startsWith('manager'))) {
    return true
  }
  return (
    activeUser.profSmsID.includes(loggedUser.id) || activeUser.familiarsID.includes(loggedUser.id)
  )
}
