import { toModel as userToModel, User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { AuthDTO } from '../models/AuthDTO'
import { LoggedUserDTO } from '../models/LoggedUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { RelatedUserDTO, toModel } from '../models/RelatedUserDTO'
import { Related, RelatedUser } from '../models/RelatedUser'
import { FamiliarData, FamiliarDataQuery } from '../models/FamiliarData'
import { FamiliarDataDTO, toModel as dataToModel } from '../models/FamiliarDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { RoleDTO, toModel as roleToModel } from '../models/RoleDTO'
import { UpdatePassword } from '../models/UpdatePassword'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { CitizenDataRes } from '../models/CitizenData'
import { CitizenDataDTO } from '../models/CitizenDataDTO'
import { RegisterUserDTO } from '../models/RegisterUserDTO'
import { UserPending } from '../models/UserPending'
import { UserPendingDTO, pendingToModel } from '../models/UserPendingDTO'
import { UserWithRelaters, UserWithRelatersDTO } from '../models/UserWithRelaters'
import { toModel as userWithRelatersToModel } from '../models/UserWithRelaters'
import { Token } from '../models/Token'
import { ExternalProfessionalPending } from '../../userTypeExternalProfessional/models/ExternalProfessionalPending'

export interface IUserApi extends IInit {
  getByID(id: string): Observable<User | undefined>

  getByUsername(username: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getParticipantList(q: Query<UserQuery>): Observable<User[]>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: User): Observable<User | undefined>

  update(e: UserDTO): Observable<User | undefined>

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>

  logout(): Observable<boolean>

  getCSRFToken(): Observable<string>

  refreshCSRFToken(): Observable<string>

  getRelated(id: string): Observable<RelatedUser | undefined>

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>>

  getUsersWithRelaters(userID: string): Observable<UserWithRelaters | undefined>

  getUsersWithRelatersLetter(
    userID: string,
    letter: number
  ): Observable<UserWithRelaters[] | undefined>

  addRelated(ru: Related, userID: string): Observable<Related>

  removeRelated(id: string, rID: string): Observable<boolean>

  getInformationByID(id: string): Observable<FamiliarData | undefined>

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>>

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>>

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  deleteInformation(id: string): Observable<boolean>

  getPermissions(): Observable<ItemList<Role>>

  invite(email: string, circleID: string, roleName: string): Observable<boolean>

  passwordRecover(email: string): Observable<boolean>

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  changePasswordLoggedUser(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  getCitizenData(e: CitizenDataDTO): Observable<CitizenDataRes | undefined>

  register(e: RegisterUserDTO): Observable<UserDTO | undefined>

  checkToken(token: string): Observable<UserPending | ExternalProfessionalPending | undefined>

  getUserPending(circleId: string): Observable<UserPending[]>

  deleteUserPending(token: string): Observable<boolean>

  getUsersByDoctor(userID: string): Observable<User[]>
  getUsersByExternal(userID: string): Observable<User[]>
  checkRoleByUsername(username: string): Observable<string[] | undefined>

  checkRecoverToken(token: string): Observable<Token | undefined>

  registerTrainer(e: UserDTO, token: string): Observable<User | undefined>
}

export class UserApi implements IUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  add(e: User): Observable<User | undefined> {
    return this._httpClient
      .post<User>({
        url: `${this._url}/externRegister`,
        body: e.toDTO(),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  register(e: RegisterUserDTO): Observable<UserDTO | undefined> {
    return this._httpClient
      .post<UserDTO>({
        url: `${this._url}/register`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/${id}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUsername(username: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/byName/${username}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._httpClient.get<User[]>({ url: prepareURL(`${this._url}/users`, q) }).pipe(
      map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getParticipantList(q: Query<UserQuery>): Observable<User[]> {
    return this._httpClient
      .get<User[]>({ url: prepareURL(`${this._url}/circleParticipant`, q) })
      .pipe(
        map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/users-pages`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/users-doctor/${q.getParam('id')}`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.put<User>({ url: `${this._url}/users/${e.id}/settings`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getRelated(id: string): Observable<RelatedUser | undefined> {
    return this._httpClient
      .get<RelatedUserDTO>({ url: prepareURL(`${this._url}/related/${id}`) })
      .pipe(
        map<RelatedUserDTO, RelatedUser>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  addRelated(ru: Related, userID: string): Observable<Related> {
    return this._httpClient.post<Related>({ url: `${this._url}/related/${userID}`, body: ru }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of({
          id: '',
          kind: 0,
        })
      })
    )
  }

  removeRelated(id: string, rID: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/related/${id}/${rID}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/user-roles`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.items = dto.items.map((d) => userToModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  getInformationByID(id: string): Observable<FamiliarData | undefined> {
    return this._httpClient.get<FamiliarData>({ url: `${this._url}/familiar-data/${id}` }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => dataToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>> {
    return this._httpClient
      .get<ItemList<FamiliarData>>({ url: prepareURL(`${this._url}/familiar-data`, q) })
      .pipe(
        map<ItemList<FamiliarDataDTO>, ItemList<FamiliarData>>((dto) => {
          const itemList = emptyList<FamiliarData>()
          itemList.items = dto.items.map((d) => dataToModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<FamiliarData>())
        })
      )
  }

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._httpClient.post<FamiliarData>({ url: `${this._url}/familiar-data`, body: e }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => {
        this._statusService.sendStatus({ variant: 'success' })
        return dataToModel(dto)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._httpClient.put<FamiliarData>({ url: `${this._url}/familiar-data`, body: e }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => {
        this._statusService.sendStatus({ variant: 'success' })
        return dataToModel(dto)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  deleteInformation(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/familiar-data/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    return this._httpClient.post<LoggedUserDTO>({ url: this._url + '/login', body: a }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  logout(): Observable<boolean> {
    return this._httpClient.post<boolean>({ url: this._url + '/logout' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getCSRFToken(): Observable<string> {
    return this._httpClient.get<string>({ url: this._url + '/csrf-token' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of('')
      })
    )
  }

  refreshCSRFToken(): Observable<string> {
    return this._httpClient.post<string>({ url: this._url + '/refresh-csrf-token' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of('')
      })
    )
  }

  getPermissions(): Observable<ItemList<Role>> {
    return this._httpClient.get<ItemList<Role>>({ url: `${this._url}/permissions` }).pipe(
      map<ItemList<RoleDTO>, ItemList<Role>>((dto) => {
        const itemList = emptyList<Role>()
        itemList.items = dto.items.map((d) => roleToModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Role>())
      })
    )
  }

  invite(email: string, circleID: string, roleName: string): Observable<boolean> {
    return this._httpClient
      .post<true>({ url: `${this._url}/users-invite/${email}/${circleID}/${roleName}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  passwordRecover(email: string): Observable<boolean> {
    return this._httpClient.post<true>({ url: `${this._url}/password-recover/${email}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._httpClient
      .put<UpdatePassword>({ url: `${this._url}/change-password`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  changePasswordLoggedUser(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._httpClient
      .put<UpdatePassword>({ url: `${this._url}/users/${e.id}/password`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getCitizenData(c: CitizenDataDTO): Observable<CitizenDataRes | undefined> {
    return this._httpClient.post<CitizenDataRes>({ url: `${this._url}/citizens`, body: c }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  checkToken(token: string): Observable<UserPending | ExternalProfessionalPending | undefined> {
    return this._httpClient
      .post<
        UserPending | ExternalProfessionalPending
      >({ url: `${this._url}/token`, body: { token } })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getUserPending(circleId: string): Observable<UserPending[]> {
    return this._httpClient
      .post<UserPending[]>({ url: `${this._url}/usersPending`, body: { circleId } })
      .pipe(
        map<UserPendingDTO[], UserPending[]>((dto) => dto.map((d) => pendingToModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  deleteUserPending(token: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/usersPending/${token}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getUsersByDoctor(userID: string): Observable<User[]> {
    return this._httpClient
      .get<User[]>({ url: prepareURL(`${this._url}/usersByDoctor/${userID}`) })
      .pipe(
        map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getUsersByExternal(userID: string): Observable<User[]> {
    return this._httpClient
      .get<User[]>({ url: prepareURL(`${this._url}/usersByExternal/${userID}`) })
      .pipe(
        map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getUsersWithRelaters(userID: string): Observable<UserWithRelaters | undefined> {
    return this._httpClient
      .get<UserWithRelaters>({ url: `${this._url}/userWithRelaters/${userID}` })
      .pipe(
        map<UserWithRelatersDTO, UserWithRelaters>((dto) => {
          return userWithRelatersToModel(dto)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getUsersWithRelatersLetter(
    userID: string,
    letter: number
  ): Observable<UserWithRelaters[] | undefined> {
    return this._httpClient
      .get<UserWithRelaters[]>({ url: `${this._url}/userWithRelatersLetter/${userID}/${letter}` })
      .pipe(
        map<UserWithRelatersDTO[], UserWithRelaters[]>((dto) => {
          return dto.map((d) => userWithRelatersToModel(d))
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  checkRoleByUsername(username: string): Observable<string[] | undefined> {
    return this._httpClient
      .get<string[]>({ url: `${this._url}/checkRoleByUsername/${username}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  checkRecoverToken(token: string): Observable<Token | undefined> {
    return this._httpClient.get<Token>({ url: `${this._url}/token-recover/${token}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  registerTrainer(e: UserDTO, token: string): Observable<User | undefined> {
    return this._httpClient
      .post<User>({
        url: `${this._url}/registerTrainer/${token}`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
