import React, { FormEvent, useState } from 'react'
import { Box, TextField } from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import { FormCard } from 'components/form-card/FormCard'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { UpdatePasswordDTO } from 'modules/users/models/UpdatePasswordDTO'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { IUserService } from '../../modules/users'
import Typography from '@mui/material/Typography'
import { Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONFIGURATION } from '../../routes/routes-constants'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export function PasswordConfig() {
  const { t } = useTranslation()
  const [oldPassword, setoldPassword] = useState('')
  const [newPassword, setnewPassword] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [errorMessage, seterrorMessage] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const loggedUser = loggedUserService.get()

  const validatePassword = () => {
    if (!newPassword) {
      seterrorMessage(t('enterNewPassword'))
      return false
    }

    const capitalRegex = /[A-Z]+/
    const lowerRegex = /[a-z]+/
    const digitRegex = /[0-9]+/
    const specialRegex = /[~!@#$%^&*=+|,.¿?\-_();:'"<>]+/
    const minimumLengthRegex = /.{8,}/
    const whiteSpaceRegex = /[\t\n\f\r ]+/

    const hasCapital = capitalRegex.test(newPassword)
    const hasLower = lowerRegex.test(newPassword)
    const hasDigit = digitRegex.test(newPassword)
    const hasSpecial = specialRegex.test(newPassword)
    const hasMinimumLength = minimumLengthRegex.test(newPassword)
    const hasNoWhiteSpace = !whiteSpaceRegex.test(newPassword)

    if (
      !hasCapital ||
      !hasLower ||
      !hasDigit ||
      !hasSpecial ||
      !hasMinimumLength ||
      !hasNoWhiteSpace
    ) {
      seterrorMessage(t('errorNewPassword'))
      return false
    }

    if (newPassword !== repeatPassword) {
      seterrorMessage(t('passwordsMatch'))
      return false
    }
    setTimeout(() => {
      goBack()
    }, 1500)

    return true
  }

  const handleNewPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validatePassword() && loggedUser) {
      seterrorMessage('')
      const newPasswordSend: UpdatePasswordDTO = {
        id: loggedUser.id,
        password: oldPassword,
        newPassword,
        token: '',
      }
      userService.changePasswordLoggedUser(newPasswordSend).subscribe(() => {
        setMessage(t('passwordsUpdated'))
      })
    }
  }

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
        {message && (
          <Box mb={3}>
            <Typography color="primary">{t('registrationOK')}</Typography>
          </Box>
        )}
        <FormCard>
          <form>
            <Box mb={3}>
              <TextField
                type="password"
                id="oldPassword"
                variant="outlined"
                label={t('oldPassword')}
                onChange={(e) => setoldPassword(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>

            <Box mb={3}>
              <TextField
                type="password"
                id="newPassword"
                variant="outlined"
                label={t('newPassword')}
                onChange={(e) => setnewPassword(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>

            <Box mb={3}>
              <TextField
                type="password"
                id="repeatPassword"
                variant="outlined"
                label={t('repeatPassword')}
                onChange={(e) => setrepeatPassword(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>

            <Box mb={3} display="flex" justifyContent="space-around">
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={handleNewPassword}
              />
            </Box>
          </form>
        </FormCard>
      </Box>
    </>
  )
}
