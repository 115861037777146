import { Button } from '@mui/material'
import styles from './AppButton.module.css'
import React, { CSSProperties } from 'react'
import { capitalize } from '../../common/utils/strings'

export enum ButtonTheme {
  NewPrimary,
  NewPrimaryResponsive,
  NewSecondary,
  BasicPrimary,
  BasicSecondary,
  BasicTrasparent,
  BasicTrasparentOrange,
  Addgroup,
  RemoveGroup,
  newOrangeButton,
  newEvent,
  newEventOrange,
  disabelEvent,
  calendarFilter,
  whiteAndBlue,
  removeField,
  BasicTransparentOutlinedOrange,
  AddCenter,
  RemoveCenter,
  BackToCalenar,
  AddCityHall,
  RemoveCityHall,
  addParticipant,
  newPrimaryRemove,
  reinviteParticipant,
  reinvitePartipantDisabled,
  borderOrangeTextBlack,
  borderBlueTextBlue,
  borderGrayActivePlan,
}

type AppButtonProps = {
  theme: ButtonTheme
  label?: string | React.ReactNode
  labelOptions?: { count: number }
  handler: (e?: any) => void
  startIcon?: string
  endIcon?: string
  span?: boolean
  type: 'button' | 'submit' | 'reset'
  vertical?: boolean
  disabled?: boolean
  value?: string
  handlerValue?: (value: string) => void
  marginStartIcon?: CSSProperties | undefined
  isLoading?: boolean
  fullWidth?: boolean
}

export function AppButton(props: AppButtonProps) {
  const handlerVal = () => {
    if (props.handlerValue && props.value) {
      props.handlerValue(props.value)
    }
  }
  const label = props.label
    ? typeof props.label === 'string'
      ? capitalize(props.label)
      : props.label
    : ''
  return (
    <Button
      className={getClassName(props.theme)}
      sx={{
        ...(props.vertical && {
          '& .MuiButton-label': {
            // Estilos para la etiqueta del botón en modo vertical
            flexDirection: 'column',
            padding: '5px',
            width: '90%',
            fontFamily: 'Poppins, sans-serif',
          },
          '& .MuiButton-startIcon': {
            // Estilos para el icono de inicio del botón en modo vertical
            marginLeft: '0',
            marginRight: '0',
          },
        }),
      }}
      color={getColor(props.theme)}
      variant="contained"
      fullWidth={props.fullWidth ? props.fullWidth : false}
      size="large"
      disableElevation={true}
      type={props.type}
      component={props.span ? 'span' : 'button'}
      onClick={props.handlerValue ? handlerVal : props.handler}
      endIcon={props.endIcon && <img src={props.endIcon} alt="endIcon" />}
      startIcon={
        props.startIcon && (
          <img style={props.marginStartIcon} src={props.startIcon} alt="startIcon" />
        )
      }
      disabled={props.disabled ? props.disabled : false}
    >
      {props.isLoading ? 'Loading...' : label}
    </Button>
  )
}

function getClassName(theme: ButtonTheme) {
  let style = styles.button

  switch (theme) {
    case ButtonTheme.BasicPrimary:
      style += ' ' + styles.button
      break
    case ButtonTheme.NewPrimary:
      style += ' ' + styles.newPrimary
      break
    case ButtonTheme.NewSecondary:
      style += ' ' + styles.newSecondary
      break
    case ButtonTheme.BasicTrasparent:
      style += ' ' + styles.transparent
      break
    case ButtonTheme.BasicTrasparentOrange:
      style += ' ' + styles.transparentOrange
      break
    case ButtonTheme.NewPrimaryResponsive:
      style += ' ' + styles.newPrimaryResponsive
      break
    case ButtonTheme.Addgroup:
      style += ' ' + styles.addGroup
      break
    case ButtonTheme.AddCenter:
      style += ' ' + styles.addCenter
      break
    case ButtonTheme.RemoveCenter:
      style += ' ' + styles.removeCenter
      break
    case ButtonTheme.RemoveGroup:
      style += ' ' + styles.removeGroup
      break
    case ButtonTheme.newEvent:
      style += ' ' + styles.newEvent
      break
    case ButtonTheme.newEventOrange:
      style += ' ' + styles.newEventOrange
      break
    case ButtonTheme.disabelEvent:
      style += ' ' + styles.disabelEvent
      break
    case ButtonTheme.newOrangeButton:
      style += ' ' + styles.newOrangeButton
      break
    case ButtonTheme.calendarFilter:
      style += ' ' + styles.calendarFilter
      break
    case ButtonTheme.whiteAndBlue:
      style += ' ' + styles.whiteAndBlue
      break
    case ButtonTheme.removeField:
      style += ' ' + styles.removeField
      break
    case ButtonTheme.BasicTransparentOutlinedOrange:
      style += ' ' + styles.transparentOutlinedOrange
      break
    case ButtonTheme.BackToCalenar:
      style += ' ' + styles.backToCalenar
      break
    case ButtonTheme.AddCityHall:
      style += ' ' + styles.addCityHall
      break
    case ButtonTheme.RemoveCityHall:
      style += ' ' + styles.removeCityHall
      break
    case ButtonTheme.addParticipant:
      style += ' ' + styles.addParticipant
      break
    case ButtonTheme.newPrimaryRemove:
      style += ' ' + styles.newPrimaryRemove
      break
    case ButtonTheme.reinviteParticipant:
      style += ' ' + styles.reinviteParticipant
      break
    case ButtonTheme.reinvitePartipantDisabled:
      style += ' ' + styles.reinvitePartipantDisabled
      break
    case ButtonTheme.borderOrangeTextBlack:
      style += ' ' + styles.borderOrangeTextBlack
      break
    case ButtonTheme.borderBlueTextBlue:
      style += ' ' + styles.borderBlueTextBlue
      break
    case ButtonTheme.borderGrayActivePlan:
      style += ' ' + styles.borderGrayActivePlan
      break
    default:
      break
  }

  return style
}

function getColor(theme: ButtonTheme) {
  const name = ButtonTheme[theme].toLowerCase()
  if (name.includes('primary')) {
    return 'primary'
  }
  if (name.includes('secondary')) {
    return 'secondary'
  }
  return 'inherit'
}
