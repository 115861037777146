import { NOTIFICATIONS_MODULE } from '../modules/notifications'
import { MESSENGER_MODULE } from '../modules/messenger'
import { MESSENGER_CONSULTA_MODULE } from '../modules/messenger_consulta'
import { CALENDAR_MODULE } from '../modules/calendar'
import { FORMS_MODULE } from '../modules/forms'
import { FILES_MODULE } from '../modules/files'
import { ROLES_MODULE, USERS_MODULE } from '../modules/users'
import { CONTENT_MODULE } from '../modules/content'
import { PATIENT_DATA_MODULE } from '../modules/patient-data'
import { DENTIST_MODULE } from '../modules/dentists'
import { PATIENT_CALENDAR_MODULE } from '../modules/patient-calendar-config'
import { VACCINES_MODULE } from '../modules/vaccines'
import { VACUSAN_MODULE } from '../modules/vaccination'
import { SETTING_MODULE } from '../modules/settings'
import { SHARED_WITH_MODULE } from '../modules/sharedWith'
import { EPILEPTICAL_CRISIS_MODULE } from '../modules/epileptical'
import { QUERY_TYPE_MODULE } from '../modules/query-type'
import { AUDIT_MODULE } from '../modules/audit'
import { ACTIVE_PLAN_MODULE } from '../modules/active-plan'
import { SPORT_CENTER_MODULE } from '../modules/sport-center'
import { TRAINING_MODULE } from '../modules/training'
import { TRAINING_GROUP_MODULE } from '../modules/training-group'
import { USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE } from '../modules/userTypeExternalProfessional'
import { USER_TRAINING_GROUP_MODULE } from '../modules/user-training-group'
import { CIRCLE_MODULE } from '../modules/circle'
import { USER_CIRCLE_MODULE } from '../modules/user-circle'
import { CIRCLE_ASSIGN_RESOURCES_MODULE } from '../modules/circle-assign-resources'
import { CIRCLE_ASSIGN_FORMS_MODULE } from '../modules/circle-assign-form'
import { CITY_HALL_MODULE } from '../modules/cityHall'
import { CONFIDENTIALITY_TERM_MODULE } from '../modules/confidentialityTerm'

// IP máquina virtual

// const apiURL = 'https://192.168.202.22:8000'
const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [NOTIFICATIONS_MODULE]: `${apiURL}/notifications`,
  [MESSENGER_MODULE]: `${apiURL}/messenger`,
  [MESSENGER_CONSULTA_MODULE]: `${apiURL}/messenger_consulta`,
  [CALENDAR_MODULE]: `${apiURL}/calendar`,
  [FORMS_MODULE]: `${apiURL}/forms`,
  [FILES_MODULE]: `${apiURL}/files`,
  [USERS_MODULE]: `${apiURL}/users`,
  [ROLES_MODULE]: `${apiURL}/users/roles`,
  [CONTENT_MODULE]: `${apiURL}/content`,
  [PATIENT_DATA_MODULE]: `${apiURL}/patient-data`,
  [DENTIST_MODULE]: `${apiURL}/dentists`,
  [PATIENT_CALENDAR_MODULE]: `${apiURL}/patient-calendar-config`,
  [VACCINES_MODULE]: `${apiURL}/vaccines`,
  [VACUSAN_MODULE]: `${apiURL}/vacusan`,
  [SETTING_MODULE]: `${apiURL}/settings`,
  [SHARED_WITH_MODULE]: `${apiURL}/files/userFiles`,
  [EPILEPTICAL_CRISIS_MODULE]: `${apiURL}/epilepticalCrisis`,
  [QUERY_TYPE_MODULE]: `${apiURL}/queryType`,
  [AUDIT_MODULE]: `${apiURL}/audit`,
  [ACTIVE_PLAN_MODULE]: `${apiURL}/activePlan`,
  [SPORT_CENTER_MODULE]: `${apiURL}/activePlan/sportCentre`,
  [TRAINING_MODULE]: `${apiURL}/activePlan/training`,
  [TRAINING_GROUP_MODULE]: `${apiURL}/activePlan/training-group`,
  [USER_TRAINING_GROUP_MODULE]: `${apiURL}/activePlan`,
  [USER_TYPE_EXTERNAL_PROFESSIONAL_MODULE]: `${apiURL}/typeExternalProfessional`,
  [CIRCLE_MODULE]: `${apiURL}/circle`,
  [USER_CIRCLE_MODULE]: `${apiURL}/user-circle`,
  [CIRCLE_ASSIGN_RESOURCES_MODULE]: `${apiURL}/circle-assign-resources`,
  [CIRCLE_ASSIGN_FORMS_MODULE]: `${apiURL}/circle-assign-forms`,
  [CITY_HALL_MODULE]: `${apiURL}/activePlan`,
  [CONFIDENTIALITY_TERM_MODULE]: `${apiURL}/confidentialityTerm`,
}
