import React, { FormEvent, useEffect, useState } from 'react'
import { ARTICLE_SERVICE_KEY, TAG_SERVICE_KEY } from '../../modules/content'
import { FormCard } from '../../components/form-card/FormCard'
import { Box, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/content-module'
import { ArticleDTO, emptyArticleDTO, fromModel } from '../../modules/content/models/ArticleDTO'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { TagService } from '../../modules/content/services/TagService'
import { Query } from '../../common/api/Query'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import genericStyle from '../../common/utils/generic.module.css'
import { forkJoin, Observable } from 'rxjs'
import { Tag } from '../../modules/content/models/Tag'
import { RichText } from '../../components/text-editor/RichText'
import { Autocomplete } from '@mui/material'
import { Alert } from '@mui/material'

enum ArticleErrors {
  NO_TAGS = 'articleMustHaveTags',
}

type EditorProps = {
  id?: string
}

interface AutocompleteTagInterface {
  key: string
  value: string
}

const contentContainer = getContentContainer()
const articleService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const tagService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [article, setArticle] = useState<ArticleDTO>(emptyArticleDTO())
  const [tagMap, setTagMap] = useState<Map<string, AutocompleteTagInterface>>(new Map())
  const [files, setFiles] = useState<F[]>([])
  const [selectedTags, setSelectedTags] = useState<AutocompleteTagInterface[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(!!props.id)
  const navigate = useNavigate()

  const getFiles = (ids: string[]): Observable<F[]> =>
    forkJoin(ids.map((id) => fileService.getByID(id)) as unknown) as Observable<F[]>

  const getTags = (ids: string[]): Observable<Tag[]> =>
    forkJoin(ids.map((id) => tagService.getByID(id)) as unknown) as Observable<Tag[]>

  useEffect(() => {
    if (props.id) {
      articleService.getByID(props.id).subscribe((res) => {
        if (res) {
          setArticle(fromModel(res))
          setIsLoading(false)

          getFiles(res.files).subscribe((res) => {
            setFiles(res.filter((f) => f))
          })

          getTags(res.tags).subscribe((res) => {
            setSelectedTags(res.filter((t) => t).map((t) => ({ key: t.id, value: t.name })))
          })
        }
      })
    }
    tagService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      const result = new Map()
      res.items.forEach((t) => {
        result.set(t.id, { key: t.id, value: t.name })
      })
      setTagMap(new Map(result))
    })
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))
  }

  const handleContent = (e: string) => {
    setArticle(Object.assign({ ...article }, { content: e }))
  }

  const handleFiles = (field: string, value: F[]) => {
    setFiles([...value])
    setArticle(Object.assign({ ...article }, { files: value.map((f) => f.id) }))
  }

  const handleTags = (value: any) => {
    setSelectedTags(value)
    setArticle(
      Object.assign({ ...article }, { tags: Array.isArray(value) ? value.map((t) => t.key) : [] })
    )
  }

  const goBack = () => navigate(ROUTE_ARTICLES)

  const validateArticle = (): boolean => {
    if (!article.tags || article.tags.length === 0) {
      setErrorMessage(ArticleErrors.NO_TAGS)
      return false
    }
    setErrorMessage('')
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateArticle()) {
      return
    }

    if (props.id) {
      articleService.update(article).subscribe((_) => goBack())
    } else {
      articleService.add(article).subscribe((_) => navigate(ROUTE_ARTICLES))
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={(e) => saveArticle(e)}>
          <Box mb={3}>
            <TextFieldItem
              field="title"
              value={article.title}
              label={'title'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box
            mb={3}
            style={{ border: '1px solid rgb(203 203 203)', borderRadius: 4, minHeight: 400 }}
          >
            {!isLoading && (
              <RichText
                content={article.content}
                handleChange={(e) => handleContent(e)}
                edit={!!props.id}
              />
            )}
          </Box>

          <Box mb={3}>
            <FileItem
              key={'files'}
              field={'files'}
              filesID={article.files}
              handleFiles={handleFiles}
              userID={loggedUser?.id ?? ''}
              cleanFiles={false}
              uploadRemoveFileDirectly={true}
              disabled={false}
              destinationFolder={'Article'}
              eventID={article.id}
              defaultFile={files}
            />
          </Box>

          <Box mb={3}>
            <Autocomplete
              key={article.id}
              id={`${article.id}-standard`}
              options={Array.from(tagMap.values())}
              getOptionLabel={(opt) => opt.value}
              multiple
              value={selectedTags}
              onChange={(_, v) => handleTags(v)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label={t('tags')} />
              )}
              noOptionsText={t('noElementsAvailable')}
            />
          </Box>

          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => {}}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
