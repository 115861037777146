import styles from '../PatientData.module.css'
import { Box, Tabs, Tab, Typography, Modal } from '@mui/material'
import stylesData from './FamiliarData.module.css'
import stylePatient from '../../user-profile/Editor.module.css'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { PatientDataDTO } from '../../../modules/patient-data/models/PatientDataDTO'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import styleMedicalData from './FamiliarData.module.css'
import { BackHeader } from '../../../components/navbar-responsive/backHeader'

type ExpandedDataProps = {
  data: PatientDataDTO
  value: number
  changeValue?: (newValue: number) => void
}

export const ExpandedMedicalData = (props: ExpandedDataProps) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentElement, setCurrentElement] = useState(<></>)
  const [currentTitle, setCurrentTitle] = useState<string>('')

  const included = (key: string) => {
    switch (key) {
      case 'nif':
        return true
      case 'cipa':
        return true
      case 'centerName':
        return true
      case 'phone':
        return true
      default:
        return false
    }
  }

  const formatDate = (value: any, key: string) => {
    if (value && key) {
      if (key.includes('Date')) return dayjs(value).format('DD/MM/YYYY HH:MM')
      if (key.includes('weight')) return value + ' kg'
      if (key.includes('height' || 'cranialPerimeter')) return value + ' cm'
      return value
    }
    return null
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handlerCloseModal = () => {
    setOpenModal(false)
  }

  const handlerOpenModal = () => {
    setOpenModal(true)
  }

  const handlerSetData = (value: any, title: string) => {
    setCurrentElement(tableComponent(value))
    setCurrentTitle(title)
    handlerOpenModal()
  }

  const tableComponent = (valueObject: any) => {
    return valueObject ? (
      <Box mb={3} className={styles.tabMainContainer} style={{ cursor: 'auto' }}>
        <Box>
          <Box className={stylePatient.mainTableContainer}>
            {valueObject ? (
              Object.entries(valueObject)
                .filter(([key, _]) => !included(key))
                .filter(([_, value]) => value)
                .map(([key, value], index) =>
                  key === 'controlledPregnancy' || key === 'meconium' || key === 'height' ? (
                    <>
                      <Box className={stylePatient.rowBox} key={key}>
                        <Box className={stylePatient.textTableTitleBox}>
                          <Box className={stylePatient.sectionLabel}>
                            {key === 'controlledPregnancy'
                              ? 'Anamnesis'
                              : key === 'meconium'
                                ? 'Período neonatal'
                                : key === 'height'
                                  ? 'Desarrollo psicomotor'
                                  : ''}
                          </Box>
                        </Box>
                      </Box>
                      <Box className={stylePatient.row}>
                        <Box className={stylePatient.cellColumn}>
                          <Box className={stylePatient.label}>{t(key)}</Box>
                        </Box>
                        <Box className={stylePatient.cellColumn}>
                          <Box className={stylePatient.labelValue}>{formatDate(value, key)}</Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box className={stylePatient.row} key={key}>
                      <Box className={stylePatient.cellColumn}>
                        <Box className={stylePatient.label}>{t(key)}</Box>
                      </Box>
                      <Box className={stylePatient.cellColumn}>
                        <Box className={stylePatient.labelValue}>{formatDate(value, key)}</Box>
                      </Box>
                    </Box>
                  )
                )
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    ) : (
      <></>
    )
  }

  const handleChange = (newValue: number) => {
    if (props.changeValue) {
      props.changeValue(newValue)
    }
  }

  return (
    <>
      {innerWidth > 599 ? (
        <Box className={styles.mainContainerOutTab}>
          <Box className={styles.mainContainerOutTab}>
            <Tabs
              value={props.value}
              onChange={(e, value) => handleChange(value)}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
              sx={{
                '& .Mui-selected': {
                  backgroundColor: 'white !important',
                  color: '#ee8c38 !important',
                },
                '& .MuiButtonBase-root.MuiTab-root': {
                  backgroundColor: '#f7fbfd',
                  color: '#b1d7ed',
                  borderTop: '#6db3dd 1px solid',
                  borderRight: '#6db3dd 1px solid',
                  borderLeft: '#6db3dd 1px solid',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  boxShadow: '1px 5px 20px rgba(0, 0, 0, 0.1)',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  fontSize: '18px',
                  padding: '5px 20px 5px 20px',
                  textTransform: 'none',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'white',
                },
                margin: '0 !important',
                padding: '0 !important',
              }}
            >
              <Tab
                className={stylePatient.labelTab}
                label={t('dataFrom0to1month')}
                {...a11yProps(0)}
              />
              <Tab
                className={stylePatient.labelTab}
                label={t('dataFrom2to6month')}
                {...a11yProps(1)}
              />
              <Tab
                className={stylePatient.labelTab}
                label={t('dataFrom7to23month')}
                {...a11yProps(2)}
              />
              <Tab
                className={stylePatient.labelTab}
                label={t('dataFrom2to5years')}
                {...a11yProps(3)}
              />
              <Tab
                className={stylePatient.labelTab}
                label={t('dataFrom6to13years')}
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <TabPanel value={props.value} index={0}>
            {props.data.zero2One && tableComponent(props.data.zero2One)}
          </TabPanel>
          <TabPanel value={props.value} index={1}>
            {props.data?.two2SixMonths && tableComponent(props.data.two2SixMonths)}
          </TabPanel>
          <TabPanel value={props.value} index={2}>
            {props.data?.seven2TwentyThreeMonths &&
              tableComponent(props.data.seven2TwentyThreeMonths)}
          </TabPanel>
          <TabPanel value={props.value} index={3}>
            {props.data?.two2FiveYears && tableComponent(props.data.two2FiveYears)}
          </TabPanel>
          <TabPanel value={props.value} index={4}>
            {props.data?.six2ThirteenYears && tableComponent(props.data.six2ThirteenYears)}
          </TabPanel>
        </Box>
      ) : (
        <Box className={stylesData.containerMainExpandedResponsive}>
          <Box className={stylesData.containerExpandedButton}>
            <AppButton
              theme={ButtonTheme.borderBlueTextBlue}
              handler={() => handlerSetData(props.data.zero2One, t('dataFrom0to1month'))}
              label={t('dataFrom0to1month')}
              type={'button'}
            />
          </Box>
          <Box className={stylesData.containerExpandedButton}>
            <AppButton
              theme={ButtonTheme.borderBlueTextBlue}
              handler={() => handlerSetData(props.data?.two2SixMonths, t('dataFrom2to6month'))}
              label={t('dataFrom2to6month')}
              type={'button'}
            />
          </Box>
          <Box className={stylesData.containerExpandedButton}>
            <AppButton
              theme={ButtonTheme.borderBlueTextBlue}
              handler={() =>
                handlerSetData(props.data?.seven2TwentyThreeMonths, t('dataFrom7to23month'))
              }
              label={t('dataFrom7to23month')}
              type={'button'}
            />
          </Box>
          <Box className={stylesData.containerExpandedButton}>
            <AppButton
              theme={ButtonTheme.borderBlueTextBlue}
              handler={() => handlerSetData(props.data?.two2FiveYears, t('dataFrom2to5years'))}
              label={t('dataFrom2to5years')}
              type={'button'}
            />
          </Box>
          <Box className={stylesData.containerExpandedButton}>
            <AppButton
              theme={ButtonTheme.borderBlueTextBlue}
              handler={() => handlerSetData(props.data?.six2ThirteenYears, t('dataFrom6to13years'))}
              label={t('dataFrom6to13years')}
              type={'button'}
            />
          </Box>
        </Box>
      )}
      <Modal open={openModal}>
        <Box className={styleMedicalData.modalContainerTable}>
          <BackHeader labelText={t('medicalData')} handlerBack={handlerCloseModal} />
          <Box className={styleMedicalData.containerRow}>
            <Box className={styleMedicalData.titleExpanded}>{currentTitle}</Box>
          </Box>
          {currentElement}
        </Box>
      </Modal>
    </>
  )
}

export default ExpandedMedicalData
