import { Box, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Navigation.module.css'
import { Link } from 'react-router-dom'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_CIRCLES,
  ROUTE_FORM_GENERATOR,
  ROUTE_MESSENGER,
  ROUTE_NOTIFICATIONS,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_RESOURCES,
  ROUTE_TAG_ARTICLES,
  ROUTE_USERS,
  ROUTE_VACCINES,
  ROUTE_SHARE_RESOURCES,
  ROUTE_QUERY,
} from '../../routes/routes-constants'
import homeIcon from '../../assets/brand_logo/home.svg'
import circleIcon from '../../assets/left_menu/circulos.svg'
import queryIcon from '../../assets/left_menu/icono-consulta.svg'
import queryActive from '../../assets/left_menu/Icono-menu-consulta-hover.svg'
import circleActive from '../../assets/left_menu/circulos-act.svg'
import circleIconHover from '../../assets/left_menu/circulos-hover.svg'
import circleManageIcon from '../../assets/left_menu/circulos-admin.svg'
import circleManageActive from '../../assets/left_menu/circulos-admin-act.svg'
import circleManageIconHover from '../../assets/left_menu/circulos-admin-hover.svg'
import notifications from '../../assets/left_menu/notificaciones.svg'
import notificationsActive from '../../assets/left_menu/notificaciones-act.svg'
import notificationsHover from '../../assets/left_menu/notificaciones-hover.svg'
import patientData from '../../assets/left_menu/datos-paciente.svg'
import patientDataActive from '../../assets/left_menu/datos-paciente-act.svg'
import patientDataHover from '../../assets/left_menu/datos-paciente-hover.svg'
import chat from '../../assets/left_menu/chat.svg'
import chatActive from '../../assets/left_menu/chat-act.svg'
import chatHover from '../../assets/left_menu/chat-hover.svg'
import calendar from '../../assets/left_menu/calendar.svg'
import calendarActive from '../../assets/left_menu/calendar-act.svg'
import calendarHover from '../../assets/left_menu/calendar-hover.svg'
import resources from '../../assets/left_menu/recursos.svg'
import resourcesActive from '../../assets/left_menu/recursos-act.svg'
import resourcesHover from '../../assets/left_menu/recursos-hover.svg'
import library from '../../assets/left_menu/library.svg'
import libraryActive from '../../assets/left_menu/library-act.svg'
import libraryHover from '../../assets/left_menu/library-hover.svg'
import forms from '../../assets/left_menu/formularios.svg'
import formsActive from '../../assets/left_menu/formularios-act.svg'
import formsHover from '../../assets/left_menu/formularios-hover.svg'
import createForms from '../../assets/left_menu/add-form.svg'
import createFormsActive from '../../assets/left_menu/add-form-act.svg'
import createFormsHover from '../../assets/left_menu/add-form-hover.svg'
import { User } from '../../modules/users/models/User'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Permission } from '../../common/permission'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import vaccineIconHover from '../../assets/left_menu/vacunasHover.svg'
import vaccineIcon from '../../assets/left_menu/vacunas.svg'
import shareWithMe from '../../assets/resource_icons/ico-share2.svg'
import { useCanSeeQuery, useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'

type LinkType = {
  route: string
  title: string
  activeIcon: string
  icon: string
  altSrc: string
  hoverIcon: string
  isVisible: boolean
  additionalRoutes: string[]
  subMenu: boolean
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type MainNavigationProps = {
  selectedMenu: string
  isChooseRole: (choose: boolean) => void
  handleRedirectToSesamo: () => void
}

// const UserCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function Navigation(props: MainNavigationProps) {
  const { t } = useTranslation()
  const path = process.env.REACT_APP_ROLE_PATH
  const viewHome = path === '/portaldelpaciente/circulo'
  // const logoutURL = process.env.REACT_APP_EXIT_URL

  const [userCircle, setUserCircle] = useState<User | undefined>(
    userCircleActiveService.getActiveUser()
  )
  const [selectedMenu, setSelectedMenu] = useState<string>(props.selectedMenu)

  const [, setMobileOpen] = useState(false)
  const canSeeQuery = useCanSeeQuery()
  const isRelated = useIsRelated()

  const links: LinkType[] = [
    {
      route: ROUTE_CIRCLES,
      title: 'circleOfPatient',
      activeIcon: circleActive,
      icon: circleIcon,
      altSrc: 'circleOfPatientIcon',
      hoverIcon: circleIconHover,
      isVisible: loggedUserService.userCan(Permission.seeCircles),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_NOTIFICATIONS,
      title: 'newsWall',
      activeIcon: notificationsActive,
      icon: notifications,
      altSrc: 'notificationsIcon',
      hoverIcon: notificationsHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_USERS,
      title: 'participants',
      activeIcon: circleManageActive,
      icon: circleManageIcon,
      altSrc: 'manageCircleIcon',
      hoverIcon: circleManageIconHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_DATA,
      title: 'patientData',
      activeIcon: patientDataActive,
      icon: patientData,
      altSrc: 'patientDataIcon',
      hoverIcon: patientDataHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_MESSENGER,
      title: 'chat',
      activeIcon: chatActive,
      icon: chat,
      altSrc: 'calendarIcon',
      hoverIcon: chatHover,
      isVisible: isRelated ? userCircle !== undefined : false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_QUERY,
      title: 'query',
      activeIcon: queryActive,
      icon: queryIcon,
      altSrc: 'queryIcon',
      hoverIcon: chatHover,
      isVisible: canSeeQuery,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CALENDAR,
      title: 'calendar',
      activeIcon: calendarActive,
      icon: calendar,
      altSrc: 'calendarIcon',
      hoverIcon: calendarHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_RESOURCES,
      title: 'resources',
      activeIcon: resourcesActive,
      icon: resources,
      altSrc: 'resourcesIcon',
      hoverIcon: resourcesHover,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_FORMS,
      title: 'patientsForms',
      activeIcon: formsActive,
      icon: forms,
      altSrc: 'patientsFormsIcon',
      hoverIcon: formsHover,
      isVisible: userCircle !== undefined && loggedUserService.userCan(Permission.userForms),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_ARTICLES,
      title: 'library',
      activeIcon: libraryActive,
      icon: library,
      altSrc: 'libraryIcon',
      hoverIcon: libraryHover,
      isVisible: true,
      additionalRoutes: [ROUTE_ARTICLES, ROUTE_TAG_ARTICLES],
      subMenu: false,
    },
    {
      route: ROUTE_FORM_GENERATOR,
      title: 'generateForms',
      activeIcon: createFormsActive,
      icon: createForms,
      altSrc: 'generateFormsIcon',
      hoverIcon: createFormsHover,
      isVisible: loggedUserService.userCan(Permission.createFormTemplates),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_VACCINES,
      title: 'vaccines',
      activeIcon: vaccineIconHover,
      icon: vaccineIcon,
      altSrc: 'vaccineIcon',
      hoverIcon: vaccineIconHover,
      isVisible: loggedUserService.userCan(Permission.configVaccines),
      additionalRoutes: [],
      subMenu: false,
    },
    {
      route: ROUTE_SHARE_RESOURCES,
      title: 'shareResources',
      activeIcon: shareWithMe,
      icon: shareWithMe,
      altSrc: 'shareResourcesIcon',
      hoverIcon: shareWithMe,
      isVisible: loggedUserService.userCan(Permission.SeeSharedResources),
      additionalRoutes: [],
      subMenu: false,
    },
  ]

  const handlerLogout = (_: React.MouseEvent<Element, MouseEvent>) => {
    props.handleRedirectToSesamo()
    props.isChooseRole(false)
    loggedUserService.logoutAndRedirectToSedePruCarm()
    localStorage.removeItem('role user')
  }

  const drawer = (
    <div className={style.listContainer}>
      <List onClick={() => setMobileOpen(false)}>
        {viewHome && (
          <Link
            onClick={(e) => {
              handlerLogout(e)
            }}
            key={'portalHome'}
            to={'https://sede.carm.es/sms/portaldelpaciente/secure/inicio.xhtml'}
            className={getLinkStyle(selectedMenu, '', '')}
            id={'portalHome'}
          >
            <ListItem
              title={t('Volver al Portal del Paciente')}
              iconSrc={homeIcon}
              altSrc={t(homeIcon)}
              hoverIconSrc={homeIcon}
              isSubMenu={false}
            />
          </Link>
        )}
        {links.map(
          (l) =>
            l.isVisible && (
              <Link
                onClick={() => {
                  /* if (l.route === ROUTE_CIRCLES) {
                    circleService.setActive(undefined)
                  } */
                  setSelectedMenu(l.route)
                }}
                key={l.title}
                to={l.route}
                className={getLinkStyle(selectedMenu, ...l.additionalRoutes, l.route)}
                id={l.route}
              >
                <ListItem
                  title={t(l.title)}
                  iconSrc={
                    selectedMenu === l.route || l.additionalRoutes.includes(selectedMenu)
                      ? l.activeIcon
                      : l.icon
                  }
                  altSrc={t(l.altSrc)}
                  hoverIconSrc={l.hoverIcon}
                  isSubMenu={l.subMenu}
                />
              </Link>
            )
        )}
      </List>
    </div>
  )

  useEffect(() => {
    setSelectedMenu(props.selectedMenu)
  }, [props.selectedMenu])

  useEffect(() => {
    userCircleActiveService.getActiveObservableUser().subscribe((c) => setUserCircle(c))
    setSelectedMenu(ROUTE_CIRCLES)
  }, [userCircle])

  return <aside className={style.aside}>{useMediaQuery('(min-width:900px)') && drawer}</aside>
}

type ListItemProps = {
  title: string
  iconSrc: string
  altSrc: string
  isDisabled?: boolean
  hoverIconSrc: string
  isSubMenu: boolean
}

function ListItem(props: ListItemProps): JSX.Element {
  return (
    <div className={style.menuDiv}>
      <Box key={props.title} className={style.menuList}>
        <Box
          className={getListItemStyle(props.isDisabled ?? false)}
          display="flex"
          alignItems="center"
        >
          {!props.isSubMenu ? (
            <img className={style.subMenuIcon} src={props.iconSrc} alt={props.altSrc} />
          ) : (
            <img className={style.menuIcon} src={props.iconSrc} alt={props.altSrc} />
          )}
          <h3 className={style.titleName}>{props.title}</h3>
        </Box>
      </Box>
    </div>
  )
}

const getLinkStyle = (menuSelected: string, ...ids: string[]): string => {
  return `${style.link} ${ids.includes(menuSelected) ? style.linkActive : ''}`
}

const getListItemStyle = (isDisabled: boolean): string =>
  `${isDisabled ? style.menuItemDisabled : style.menuItem}`
